import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Menu from './Menu/Menu';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Main from './Main/Main';
import VideoPopup from './Popup/VideoPopup';
import Projects from './Projects/Projects';
import About from './About/About';
import Services from './Services/Services';
import Starfall from './Projects/Starfall/Starfall';
import GamiTrading from './Projects/GamiTrading/GamiTrading';
import Pleh from './Projects/Pleh/Pleh';
import DataSpike from './Projects/DataSpike/DataSpike';
import Space from './Projects/Space/Space';
import Springbox from './Projects/Springbox/Springbox';
import Acme from './Projects/Acme/Acme';
import Definite from './Projects/Definite/Definite';
import Doto from './Projects/Doto/Doto';
import AssistAlong from './Projects/AssistAlong/AssistAlong';
import Sikka from './Projects/Sikka/Sikka';
import Finangel from './Projects/Finangel/Finangel';
import ContactUs from './ContactUs/ContactUs';
import PitchDeck from './PitchDeck/PitchDeck';
import InfoPopup from './Popup/InfoPopup';

function App() {
  const history = useHistory();
  const location = useLocation();
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [infoPopup, setInfoPopup] = useState(true);
  const [isInfoPopupOpen, setInfoPopupOpen] = useState(false);
  const body = document.getElementById('body');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  function backHome() {
    history.push('/');
    closeMenu();
  }

  function openPopup() {
    setVideoPopupOpen(true);
    closeMenu();
  }

  function closePopup() {
    setVideoPopupOpen(false);
  }

  function openMenu() {
    setMenuOpen(true);
    body.classList.add('overflow-hidden');
  }

  function closeMenu() {
    setMenuOpen(false);
    body.classList.remove('overflow-hidden');
  }

  function openMail() {
    window.open('mailto:hi@makeitwow.io')
  }

  return (
    <div className="page">
      <Switch>
        <Route exact path='/'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_black'}
            menu={'menu__title menu__title_type_black'}
            burger={'menu__burger menu__burger_type_black'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Main
            history={history}
            onVideo={openPopup}
          />
        </Route>
        <Route exact path='/about'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_black'}
            menu={'menu__title menu__title_type_black'}
            burger={'menu__burger menu__burger_type_black'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <About
            history={history}
          />
        </Route>
        <Route exact path='/services'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_black'}
            menu={'menu__title menu__title_type_black'}
            burger={'menu__burger menu__burger_type_black'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Services
            history={history}
          />
        </Route>
        <Route exact path='/projects'>
          <Header
            onClick={backHome}
            background={'header_type_background-black'}
            logo={'header__logo_type_white'}
            menu={'menu__title menu__title_type_white'}
            burger={'menu__burger menu__burger_type_white'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Projects
            history={history}
          />
        </Route>
        <Route exact path='/projects/starfall'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Starfall
            history={history}
          />
        </Route>
        <Route exact path='/projects/gami-trading'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <GamiTrading
            history={history}
          />
        </Route>
        <Route exact path='/projects/pleh'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Pleh
            history={history}
          />
        </Route>
        <Route exact path='/projects/dataspike'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <DataSpike
            history={history}
          />
        </Route>
        <Route exact path='/projects/space307'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Space
            history={history}
          />
        </Route>
        <Route exact path='/projects/springbox'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Springbox
            history={history}
          />
        </Route>
        <Route exact path='/projects/acme'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Acme
            history={history}
          />
        </Route>
        <Route exact path='/projects/definite'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Definite
            history={history}
          />
        </Route>
        <Route exact path='/projects/doto'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Doto
            history={history}
          />
        </Route>
        <Route exact path='/projects/assistalong'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <AssistAlong
            history={history}
          />
        </Route>
        <Route exact path='/projects/sikka'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Sikka
            history={history}
          />
        </Route>
        <Route exact path='/projects/finangel'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_purple'}
            menu={'menu__title menu__title_type_purple'}
            burger={'menu__burger menu__burger_type_purple'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <Finangel
            history={history}
          />
        </Route>
        <Route exact path='/contact-us'>
          <Header
            onClick={backHome}
            background={'header_type_background-white'}
            logo={'header__logo_type_black'}
            menu={'menu__title menu__title_type_black'}
            burger={'menu__burger menu__burger_type_black'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <ContactUs
            setInfoPopup={setInfoPopup}
            openPopup={setInfoPopupOpen}
          />
        </Route>
        <Route exact path='/pitch-deck'>
          <Header
            onClick={backHome}
            background={'header_type_background-black'}
            logo={'header__logo_type_white'}
            menu={'menu__title menu__title_type_white'}
            burger={'menu__burger menu__burger_type_white'}
            openMenu={openMenu}
            openMail={openMail}
          />
          <PitchDeck
            openMail={openMail}
            history={history}
            setInfoPopup={setInfoPopup}
            openPopup={setInfoPopupOpen}
          />
        </Route>
      </Switch>
      <Footer
        openMail={openMail}
      />
      <VideoPopup
        isOpen={isVideoPopupOpen}
        onClose={closePopup}
      />
      <Menu
        isOpen={isMenuOpen}
        onClose={closeMenu}
        history={history}
        openMail={openMail}
      />
      <InfoPopup
        infoPopup={infoPopup}
        isOpen={isInfoPopupOpen}
        openPopup={setInfoPopupOpen}
      />
    </div>
  );
}

export default App;